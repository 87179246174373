@import '../../../../../../scss//vars.scss';

.section {
  display: flex;
  flex-direction: column;
  width: 1076px;
  padding: 40px 40px 16px;
  background: $blackLetters;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.title {
  margin: 24px 0 12px 21px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  cursor: default;
}

.table {
  width: max-content;
  position: relative;
  right: 40px;
  border-collapse: collapse;
  margin: 0 0 0 19px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: white;
  cursor: default;
}

/* Стили для боковых границ между колонками */
.table .th:not(:first-child),
.table .td:not(:first-child) {
  border-left: 1px solid #40444B;
}

.table .th:not(:last-child),
.table .td:not(:last-child) {
  border-right: 1px solid #40444B;
}

.thSmall {
  padding: 0 8px;
  width: 40px;
  text-align: center;
  vertical-align: bottom;
}

.th {
  border-top: 1px solid #40444B;
  border-bottom: 1px solid #40444B;
  padding: 0 8px;
  text-align: left;
  font-weight: 400;
}

.td {
  border-bottom: 1px solid #40444B;
  padding: 0 8px;
  white-space: nowrap;
  text-align: left;
  font-weight: 400;
}

.tr {
  height: 40px;
}

.deleteButton {
  width: 40px;
  height: 40px;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #40444B;
  border-right: 0;
}

.openButton {
  width: 71px;
  height: 24px;
  margin-left: 60px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #34373D;
  color: #95999E;
  border-radius: 3px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
}

.counter {
  display: flex;
  position: fixed;
  align-items: center;
  gap: 8px;
  bottom: 16px;
  margin-left: 21px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: white;
  cursor: default;
}
