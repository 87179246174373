@import '../../../../scss/typography.scss';
@import '../../../../scss/vars.scss';

.item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px 20px;
  gap: 8px;
  width: 100%;
  border-radius: 16px;
  position: relative;

  & > svg {
    display: block;
  }

  code {
    background-color: rgb(40, 44, 52);
    color: rgb(97, 175, 239);
    border-radius: 8px;
    padding: 4px 6px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px;
  gap: 2px;
  width: 579px;
  line-height: 24px;
  background: $backgroundColor;
  border-radius: 16px 16px 16px 0px;
}

.message {
  display: block;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $whiteColor;
  word-wrap: anywhere;

  & > ol {
    & > li {
      display: flex;
      flex-wrap: wrap;

      & > p {
        display: list-item;
      }
    }
  }

  & > table {
    padding: 0;
    border-collapse: 0;
    border: 0.25px solid $whiteColor;
    border-spacing: 0;
  }

  & > tr,
  td,
  th {
    padding: 4px 8px;
    border: 0.25px solid $whiteColor;
  }

  & > * {
    white-space: pre-wrap;
  }
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background: $backgroundColor;

  & > svg {
    display: block;
  }
}

.image {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.request {
  background: $chatRequestBackgroundColor;

  & > p {
    white-space: pre-wrap;
  }

  & > tr,
  td,
  th {
    padding: 4px 8px;
    border: 0.25px solid $whiteColor;
  }
}
