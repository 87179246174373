@import '../../../../../../scss/typography.scss';
@import '../../../../../../scss/vars.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 12px;
    justify-content: space-between;
    width: 647px;
    height: 24px;
    background: $toastBackgroundDark;
    border-radius: 8px;
}

.description {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: $blueInputPendingColor;
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 0px;
    gap: 4px;
    justify-content: space-between
}

.command {
    @extend .description;
    color: $suItemsInSubList;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      cursor: pointer;
    }
}

.key {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    color: #95999E;
}
