.button {
  width: 66px;
  height: 24px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  gap: 4px;
  background: #4BBDEA;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #2F3136;
}
