@import '../../../../scss//typography.scss';
@import '../../../../scss/vars.scss';

.botAssistantsWrapper {
  position: fixed;
  top: 56px;
  background: #2f3136;
  z-index: 1;
  padding: 16px 0 0;
  border-top: 1px solid #666;
  width: 665px;
}

.scroll {
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
  flex-direction: column-reverse;

  justify-content: space-between;
    height: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }
}

.section {
  max-width: 662px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding-bottom: 37px;
  margin-top: 110px;
}
