@import '../../../../../../scss/vars.scss';

.header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 21px;
  position: fixed;
  min-width: 375px;
  width: 100%;
  height: 48px;
  top: 0px;
  background: $blackLetters;
  border-bottom: 0.5px solid $alfAIMenuItemTextColor;
  z-index: 5;
  &>svg {
    display: block;
  }
}

.returnButton {
  padding: 0;
  margin: 0;
  border: 0;
  background: 0;

  &>svg {
    display: block;
  }

  transition: ease-in-out;

  &:hover {
    cursor: pointer;
  }
}

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: $whiteColor;
}
