@import '../../../scss/vars.scss';

.container {
  width: 672px;
  padding: 26.5px 30px 16px 30px;
  background-color: $backgroundColorPrimary;
  color: $textTertiary;
  overflow: auto;
  height: 100vh;
  & h1 {
    margin: 0px;
    padding-bottom: 2.5px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > button {
      background-color: transparent;
      color: $whiteColor;
      border: 1px solid $greyTertiary;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding: 8px 34px;
      border-radius: 8px;
    }
  }

  .btn_box {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    .btn_yellow {
      color: $backgroundColorPrimary;
      background-color: $yellowBackground;
      padding: 8px 16px;
      gap: 8px;
      width: 220px;
      height: 40px;
      border-radius: 8px;
      border: none;
    }
    .btn_gray {
      color: $greyHover;
      background-color: transparent;
      padding: 8px 32px;
      gap: 8px;
      width: 116px;
      height: 40px;
      border-radius: 8px;
      border: none;
      &:hover {
        background-color: $greyTertiary;
        color: $backgroundColorPrimary;
      }
    }
  }

  .error {
    padding-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $errorColor;
  }
}
