.chatHistoryPage {
  background-color: #01031F;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.chatHistoryPage__dialogList {
  height: calc(100vh - 152px);
  overflow: auto;
  list-style: none;
  padding: 0;
  margin-bottom: 90px;
}

.chatHistoryPage__dialogList::-webkit-scrollbar {
  width:0px;
}

.chatHistoryPage__spinnerContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
