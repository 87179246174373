.sliderContainer {
  position: relative;
}

.slider {
  width: 100%;
  height: 8px;
  border-radius: 8px;
  box-shadow: 4px 4px 6px 0px rgba(255, 255, 255, 0.50) inset;
  cursor: pointer;
}

.values {
  display: flex;
  justify-content: space-between;
  width: 101%;
  margin-left: -0.5%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #FFFFFF;
}

.value {
  width: 21px;
  text-align: center;
  cursor: default;
}

.valueWindow {
  position: absolute;
  bottom: 43px;
  background-color: #95999E;
  padding: 1px 4px;
  border-radius: 2px;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  color: #2F3136;
  z-index: 999;

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 3.5px;
    border-style: solid;
    border-color: #95999E transparent transparent transparent;
  }
}
