@import '../../../../../../../../../../scss/vars.scss';

.overlay {
  position: fixed;
  background: $pitchBlackColor;
  opacity: .6;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.modal {
  width: 345px;
  height: 204px;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 20px 24px 20px;
  border-radius: 8px;
  border-width: 0px 0.5px 0.5px 0.5px;
  gap: 32px;
  border-color: $brownishGreyColor;
  border-style: solid;
  background: $toastBackgroundDark;
  z-index: 3;
}

.title {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: $textTertiary;
}

.button {
  border-radius: none;
  border: none;
  padding: 0;
  background: none;

  &>svg {
    display: block;
  }

  transition: 0.45s cursor ease-out;

  &:hover {
    cursor: pointer;
    transition: 0.25s cursor ease-in;
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  border: 1px;
  gap: 16px;  
  background: $backgroundColor;
  color: $whiteColor;
  &:focus {
    outline: 0.5px solid $yellowBackground;
  }

  &::placeholder {
    color: $suItemsInSubList;
  }

}

.cancelButton {
  @extend .button;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 117px;
  height: 40px;
  border-radius: 8px;
  gap: 8px;
  color: $whiteColor;
  background: $toastBackgroundDark;
}

.renameButton {
  @extend .button;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: $blackLetters;
  background: $yellowBackground;
  width: 142px;
  height: 40px;
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  gap: 8px;

}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spinner {
  margin: auto;
}
