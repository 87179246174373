@import '../../../../../../scss/vars.scss';

.checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 34px;
  height: 18px;
  background: #95999E;
  border-radius: 100px;
  position: relative;
}

.label .button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 45px;
  background: #2F3136;
}

.checkbox:checked+.label {
  background: #4BBDEA;
}

.checkbox:checked+.label .button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background: #fff;
}

.label:active .button {
  width: 20px;
}
