@import '../../../../../../../../scss/vars.scss';

.menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 16px;
  position: absolute;
  height: 32px;
  bottom: -32px;
  right: 0px;
  background: $backgroundColorPrimary;
  border: 0.5px solid $brownishGreyColor;
  z-index: 15;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.button {
  border: 0;
  padding: 0;
  background: none;

  &>svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}
