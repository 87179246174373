@import '../../../../../../../../../../scss/vars.scss';
@import '../../../../../../../../../../scss/typography.scss';

.container {
  display: flex;
  align-items: center;
  width: 647px;
  background-color: $backgroundColor;
  padding: 8px;
  border-radius: 8px;
  min-height: 40px;
  cursor: pointer;
}
.text{
  color: $whiteColor;
  padding-left: 6px;
}

.containerActive{
  background-color: $backgroundColorVoiceActive;
}
.voiceIcon {
  display: flex;
  &:focus-visible {
    outline: none;
  }
}

.iconsList {
  display: flex;
  list-style-type: none;
  align-self: center;
  gap: 6px;
  .iconsItem {
    position: relative;
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    padding: 2px;
    transition: all .3s ease-in-out;
    .textTooltip {
      visibility: hidden;
      opacity: 0;
      font-size: 12px;
      border-radius: 2px;
      color: #2B2D32;
      z-index: 100;
      position: absolute;
      top: -35px;
      left: -40px;
      padding: 4px 8px;
      background-color:#95999E;
      transition: all .3s ease-in-out;
    }
    &:hover {
      background-color: $toastBackgroundDark;
      z-index: 100;
      z-index: 10000;
      .textTooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.textarea {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 12px;
  gap: 12px;
  width: 579px;
  background: transparent;
  border: 0;
  border-radius: 8px;
  text-align: bottom;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: $whiteColor;
  outline: none;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  white-space: pre-wrap;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }

  &::placeholder {
    display: block;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}
