@import '../../../../../../../../scss/vars.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 16px 20px 0px 26px;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background: $toastBackgroundDark;
}

.description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  color: $blueInputPendingColor;
}

.command {
  @extend .description;
  color: $suItemsInSubList;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  color: $suItemsInSubList;

  &:hover {
    cursor: pointer;
  }
}
