@import '../../../../scss/vars.scss';
@import '../../../../scss/typography.scss';

.container {
  min-width: 196px;
  background: $toastBackgroundDark;
  box-shadow: $headerBoxShadow;
  border-radius: 8px;
  position: absolute;
  top: 47px;
  right: -4px;
  font-family: 'Manrope', sans-serif;
  z-index: 5;
  color: $whiteColor;
  border: 0.5px solid $brownishGreyColor;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 400px;
  padding: 4px;

  &::before {
    position: absolute;
    top: -7px;
    right: 13px;
    content: '';
    width: 12px;
    height: 12px;
    background: $toastBackgroundDark;
    border: 0.5px solid $brownishGreyColor;
    border-bottom-color: $toastBackgroundDark;
    border-right-color: $toastBackgroundDark;
    transform: rotate(45deg);
    z-index: 1;
  }
}

.userInfo {
  padding: 8px 16px;
  white-space: nowrap;
}

.username {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

// .email {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 16px;
//   display: flex;
//   align-items: center;
//   color: #95999e;
// }

.button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;

  /* Text/secondary */

  color: $textTertiary;
  padding: 4px 8px;
  width: 100%;
  background: transparent;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    background: $backgroundColor;
    color: $whiteColor;
  }
}

.item {
  border-radius: 8px;
  background-color: #36393F;
  display: flex;
  margin: 6px;
  position: relative;
  border: none;
  outline: none;
  transform: translateY(0);
  box-shadow: none;
}

.item:hover {
  background-color: #36393fb6;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.13);
  transition: transform 0.5s ease,
              box-shadow 0.25s linear,
              background-color 0.25s linear;
}

.item:hover > .itemButton {
  background-color: #2f313680;
  transition: background-color 0.25s linear;
}

.itemColWrapper {
  width: 100%;
  display: flex;
}

.itemRow {
  justify-content: center;
  align-items: center;
  height: 54px;
  padding: 8px 12px;
  flex: 1;
}

.itemCol {
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: max-content;
  padding: 8px 12px;
  flex: 0.5;
}

.itemRowContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.itemTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: left;
  align-self: flex-start;
}

.itemTitleDouble {
  line-height: 19px;
}

.itemTitleThin {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-left: 6.15px;
  text-align: left;
}

.itemSubtitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  align-self: flex-start;
  color: #95999E;
  text-align: left;
}

.itemRowIcon {
  margin-right: 6px;
  border-radius: 50%;
  background-color: #2F3136;
  overflow: hidden;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.itemColIcon {
  margin-right: 6.15px;
  border-radius: 50%;
  overflow: hidden;
  width: 15px;
  height: 15px;
}

.itemButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: max-content;
  height: 32px;
  border: 1px solid #666666;
  border-radius: 8px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background-color: #36393F;
  color: #FFFFFF;
  margin-left: auto;

  &_span {
    display: inline-block;
    margin-right: 8px;
  }
}

.itemButton:hover {
  background-color: #27282c79 !important;
  transition: background-color 0.25s linear;
}

.buttonIcon {
  margin-right: 7.5px;
}

.itemScale {
  width: 100%;
  height: 3px;
  background: #2F3136;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 8px;
  margin-bottom: 8px;
}

.itemScaleFill {
  height: 100%;
  background: #FCD570;
  border-radius: 10px;
}

.itemNotification {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FBC333;
  border-radius: 8px;
  left: 32px;
  top: 6px;
  width: max-content;
  height: max-content;
}

.itemNotificationCount {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #2F3136;
  margin: 1px 4px;
}

.right {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);

  & path {
    stroke: #95999e;
    stroke-width: 2.3;
  }
}

