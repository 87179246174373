@import '../../../../scss/typography.scss';
@import '../../../../scss/vars.scss';

.page {
  display: flex;
  flex-direction: column;
  background: #40444b;
  align-items: center;
  justify-content: center;

  height: 100vh;

  &__container {
    width: 796px;
    margin: 0 auto;
  }

  &__img {
    display: flex;
    justify-content: flex-end;

    width: 510px;
    height: 397px;
    background-image: url('../../images/noAccess.png');
    background-repeat: no-repeat;
    margin: 77px auto;
  }

  &__title {
    display: flex;
    justify-content: center;

    margin-bottom: 30px;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    color: $whiteColor;

    text-transform: uppercase;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include mediumH3($whiteColor);
    line-height: 27px;

    &_thank {
      margin-top: 30px;
    }
  }
}
