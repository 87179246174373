@import '../../scss/vars.scss';

.avatar {
  display: flex;
  align-items: center;
  // margin: 0 0 0 8px;
  // width: 226px;

  .circle {
    position: relative;
    //width: 38px;
    //height: 38px;
    //border: 2px solid $backgroundColor;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: $yellowBackground;
    }

    .userbtn {
      border-radius: 50%;

      img {
        border-radius: 50%;
      }
    }
  }

  .circle_active {
    border-color: $yellowBackground !important;
  }
}

.subsDetailsContainer {
  margin: 0;
  padding: 0;

  cursor: pointer;

  border-radius: 8px;

  white-space: nowrap;

  &:hover {
    background: #40444b;
  }
}

.subsDetailsName {
  color: #ffffff;

  font-family: 'Manrope';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;

  text-align: right;
}

.subsDetailsDuration {
  color: #95999e;

  font-family: 'Manrope';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  white-space: nowrap;
}

.userbtn {
  width: 32px;
  height: 32px;

  border: none;
  background-color: $backgroundColor;

  & > img {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
}

@media (max-width: 1024px) {
  .avatar {
    // width: 100%;
    grid-column-start: 3;
    justify-content: flex-end;
  }
}
