@import '../../scss/vars.scss';

.app_container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  // margin-top: 56px;
}

.section {
  height: 100%;
}

.prp-header-logo {
  display: flex !important;
  align-items: center !important;
}

.container_wrapper {
  height: calc(100% - 52px);
}

.spinner_container {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .section {
    min-width: initial !important;
  }
}
