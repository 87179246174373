@import '../../../../../../scss/vars.scss';

.AlfAssistantVariablesMenu_poppup {
  max-height: 180px;
  max-width: 667px;
  overflow-y: auto;
  background: #2b2d32;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }
  ul li,
  .CreateVariablePoppup {
    margin: 0;
    min-width: 180px;
    font-size: 14px;
    outline: none;
    line-height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    .AlfAssistantVariablesMenu_poppup_text {
      padding: 6px 8px;
      flex: 1 1;
      color: white;
    }
  }
  ul li:hover {
    background-color: #34373d;
  }
}
.NoVariables {
  font-family: 'Manrope', sans-serif;
  padding: 1rem;
  color: white;
}
.CreateVariablePoppup {
  text-align: left;
  width: 100%;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}
