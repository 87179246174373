@import '../../scss/vars.scss';

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 12;
  right: 0;
  bottom: 32px;
  padding: 4px 8px;
  gap: 8px;
  width: 40px;
  height: 32px;
  background: $inputBackground;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: $brownishGreyColor;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px 0px 0px 15px;
}
