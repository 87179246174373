@import '../../../../../../scss/vars.scss';

.ai_command_item {
  border: 0;
  display: flex;
  align-items: center;
  background: none;
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  gap: 8px;
  width: 100%;
  //vertical-align: middle;
  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: $grayPlatinum;
  }

  & span {
    display: flex;
    align-items: center;
  }
}

.ai_command_item_title {
  flex: 1;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: $whiteColor;
  text-align: start;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &_blocked {
    color: #606368;
  }
}

@media (max-width: 768px) {
  .ai_command_item {
    padding: 10px 14px 10px 16px;
    border-radius: 0px;
    border-bottom: 0.5px solid #66666680;
  }
}
