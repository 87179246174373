@import './../../../../../scss/vars.scss';
@import './../../../../../scss/typography.scss';

.tabsPanel {
  display: flex;
  gap: 24px;
  min-height: 500px;
  border-radius: 10px;
  background: radial-gradient($blueCrayolaColor, $blueAzureColor);
  box-shadow: 0 30px 30px 10px rgba(48, 47, 187, 0.2);

  .tabsPanel__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 50%;
    min-height: 100%;
    padding: 32px;

    .tabsPanel__leftTop {
      .tabsPanel__header {
        margin-bottom: 24px;
        font-family: 'Inter';
        font-size: 24px;
        font-weight: $fontWeightBold;
        line-height: 29px;
        color: $whiteColor;
      }

      .tabsPanel__list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        list-style: none;

        .tabsPanel__item {
          display: flex;
          gap: 16px;
          align-items: center;
          padding: 4px;

          svg {
            min-width: 32px;
            min-height: 32px;
          }

          .tabsPanel__advantageDescription {
            max-width: 420px;
            font-family: 'Inter';
            font-size: 17px;
            font-weight: $fontWeightRegular;
            line-height: 24px;
            color: $whiteColor;
          }
        }
      }
    }

    .tabsPanel__link {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 12px 0;
      font-family: 'Inter';
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      color: $whiteColor;
      text-decoration: none;

      @keyframes arrow {
        from {
          transform: translateX(0);
        }

        25% {
          transform: translateX(15px);
        }

        50% {
          transform: translateX(7px);
        }

        75% {
          transform: translateX(15px);
        }

        100% {
          transform: translate(0);
        }
      }

      svg {
        transition: transform 0.5s ease;
      }

      &:hover svg {
        animation-name: arrow;
        animation-duration: 1s;
      }
    }
  }

  .tabsPanel__right {
    width: 100%;
    padding: 32px;
    .tabsPanel__video {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 1150px) {
  .tabsPanel {
    flex-direction: column;
    gap: 16px;
    min-height: auto;
    padding: 20px;

    .tabsPanel__left {
      flex: none;
      gap: 16px;
      min-height: auto;
      padding: 0;

      .tabsPanel__leftTop {
        .tabsPanel__header {
          font-size: 19px;
          line-height: 27px;
        }

        .tabsPanel__list {
          .tabsPanel__item {
            padding: 0;

            .tabsPanel__advantageDescription {
              max-width: none;
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
      }

      .tabsPanel__link {
        font-size: 15px;
        line-height: 21px;
      }
    }

    .tabsPanel__right {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-top: 20px;
      .tabsPanel__video {
        width: auto;
        height: 280px;
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .tabsPanel {
    padding: 20px;

    .tabsPanel__left {
      gap: 24px;
    }

    .tabsPanel__link {
      justify-content: space-between;
      padding: 12px 0;
    }

    .tabsPanel__right {
      .tabsPanel__video {
        height: auto;
        width: 100%;
      }
    }
  }
}
