
@font-face {
  font-family: 'BravoRG';
  src:
  url('./bravo/bravorg.woff2') format('woff2'),
  url('./bravo/bravorg.woff') format('woff'), 
  url('./bravo/bravorg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  src: url('./Manrope/Manrope-Bold.ttf') format('truetype');
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
}
@font-face {
  src: url('./Manrope/Manrope-ExtraBold.ttf') format('truetype');
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
}

@font-face {
  src: url('./Manrope/Manrope-ExtraLight.ttf') format('truetype');
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
}
@font-face {
  src: url('./Manrope/Manrope-Light.ttf') format('truetype');
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
}
@font-face {
  src: url('./Manrope/Manrope-Medium.ttf') format('truetype');
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
}
@font-face {
  src: url('./Manrope/Manrope-Regular.ttf') format('truetype');
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
}
@font-face {
  src: url('./Manrope/Manrope-SemiBold.ttf') format('truetype');
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro ExtraLight'), 
  local('SourceSansPro-ExtraLight'), 
  url('./SourseSansPro/sourcesansproextralight.woff2') format('woff2'),
  url('./SourseSansPro/sourcesansproextralight.woff') format('woff'),
  url('./SourseSansPro/sourcesansproextralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro ExtraLight Italic'), 
  local('SourceSansPro-ExtraLightIt'),
  url('./SourseSansPro/sourcesansproextralightitalic.woff2') format('woff2'),
  url('./SourseSansPro/sourcesansproextralightitalic.woff') format('woff'),
  url('./SourseSansPro/sourcesansproextralightitalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Light'),
  local('SourceSansPro-Light'), 
  url('./SourseSansPro/sourcesansprolight.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansprolight.woff') format('woff'), 
  url('./SourseSansPro/sourcesansprolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Light Italic'), 
  local('SourceSansPro-LightIt'), 
  url('./SourseSansPro/sourcesansprolightitalic.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansprolightitalic.woff') format('woff'), 
  url('./SourseSansPro/sourcesansprolightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'), 
  local('SourceSansPro-Regular'), 
  url('./SourseSansPro/sourcesanspro.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesanspro.woff') format('woff'), 
  url('./SourseSansPro/sourcesanspro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Italic'), 
  local('SourceSansPro-It'), 
  url('./SourseSansPro/sourcesansproitalic.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansproitalic.woff') format('woff'), 
  url('./SourseSansPro/sourcesansproitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Semibold'), 
  local('SourceSansPro-Semibold'), 
  url('./SourseSansPro/sourcesansprosemibold.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansprosemibold.woff') format('woff'), 
  url('./SourseSansPro/sourcesansprosemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Semibold Italic'), 
  local('SourceSansPro-SemiboldIt'), 
  url('./SourseSansPro/sourcesansprosemibolditalic.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansprosemibolditalic.woff') format('woff'), 
  url('./SourseSansPro/sourcesansprosemibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Bold'), 
  local('SourceSansPro-Bold'), 
  url('./SourseSansPro/sourcesansprobold.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansprobold.woff') format('woff'), 
  url('./SourseSansPro/sourcesansprobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Bold Italic'), 
  local('SourceSansPro-BoldIt'), 
  url('./SourseSansPro/sourcesansprobolditalic.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansprobolditalic.woff') format('woff'), 
  url('./SourseSansPro/sourcesansprobolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Black'), 
  local('SourceSansPro-Black'), 
  url('./SourseSansPro/sourcesansproblack.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansproblack.woff') format('woff'), 
  url('./SourseSansPro/sourcesansproblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Black Italic'), 
  local('SourceSansPro-BlackIt'), 
  url('./SourseSansPro/sourcesansproblackitalic.woff2') format('woff2'), 
  url('./SourseSansPro/sourcesansproblackitalic.woff') format('woff'), 
  url('./SourseSansPro/sourcesansproblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'checkfont';
  src:
    url('./checkfont/checkfont.ttf') format('truetype'),
    url('./checkfont/checkfont.woff') format('woff'),
    url('./checkfont/checkfont.svg?g8ljoq#checkfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}