//@keyframes translate {
//  0% {
//    right: -100%;
//  }
//  100% {
//    right: 0;
//  }
//}
@import '../../../scss/vars.scss';
.drawer {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0%;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 1s ease, visibility 0s ease;
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  &.one {
    background-color: rgba(0, 0, 0, 0);
    z-index: 1001;
    backdrop-filter: blur(2px);
  }
  &.active {
    opacity: 1;
    visibility: visible;
    left: 0%;
  }
  &.noBackground {
    background: none;

    backdrop-filter: none;
  }

  &.overlay {
    z-index: 31001;
    opacity: 0.6;
    transition: right 0.6s ease-out;
  }
}

.slider__drawer {
  position: fixed;
  z-index: 31001;
  top: 0;
  right: -100%;
  max-width: 100%;
  transition: right 0.6s ease-out;

  height: 100%;
  //animation: translate 0.5s linear;
  @media (max-width: 1280px) {
    width: 100%;
  }
  &.active {
    right: 0%;

    &.one {
      box-shadow: -30px 0px 100px rgba(0, 0, 0, 0.25);
    }
  }
  &.one {
    z-index: 1002;
    background-color: $backgroundColorPrimary;
  }
}

.children {
  height: 100%;
}

.close {
  position: absolute;
  z-index: 1;
  transition: all 1s ease-out;

  left: -114px;
  top: 16px;

  display: flex;

  width: 114px;
  padding: 12px 16px;

  border-radius: 8px 0 0 8px;
  background: #36393f;

  &:hover {
    background-color: $brownishGreyColor;
  }

  &.btn__variables {
    border-radius: 0px 0px 0px 4px;
  }

  &.btn__padding__top {
    top: 56px;
  }
  @media (max-width: 1440px) {
    right: 0;
    left: auto;
    top: 16px;
    width: 60px;

    background: none;
  }
}

.close_text {
  display: flex;
  align-items: center;

  margin: -4px 0 0 21px;

  text-align: center;

  color: #ffffff;

  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
}

.closeIcon {
  display: flex;
  justify-content: space-between;

  width: 16px;
  height: 16px;
  margin: auto 8px auto 0;

  cursor: pointer;

  border: none;
  outline: none;
  background-color: transparent;
  background-image: url('../../../assets/icons/closeIconWhite.svg');
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1280px) {
    position: static;
    width: 30px;
    height: 30px;

    cursor: pointer;

    background-color: $backgroundColorPrimary;
    background-size: cover;
  }
}

@media (max-width: 1440px) {
  .close_text {
    display: none;
  }
}
