.aura {
  position: absolute;
  visibility: hidden;
  opacity: 0.5;
  z-index: -3;
  width: 500px;
  height: 500px;
  background: url(../../../../assets/images/Aura.png) no-repeat;
  transform: translate(-70%, 10%);
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  @media screen and (max-width: 800px) {
    display: none;
  }
}
