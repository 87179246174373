@import '../../../../scss/typography.scss';
@import '../../../../scss/vars.scss';

.section {
  display: flex;
  flex-direction: column;
  width: 667px;
  padding: 24px 20px;
  gap: 24px;
  background: $blackLetters;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.buttons {
  display: flex;
  gap: 16px;
}

.button {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 0;
  border-radius: 8px;
}

.cancelButton {
  @extend .button;
  width: 101px;
  color: #AAABAD;
  // background: #32353A;
}
