@import '../../../../../scss/vars.scss';

.container {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.btn__box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.body {
  padding-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $whiteColor;
}
