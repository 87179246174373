@import '../../../../scss/vars.scss';
@import '../../../../scss/typography.scss';

.header {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px 24px 12px;
  max-width: 1213px;
  width: 100%;
  height: 93px;
  background: $headerBackgroundColor;
  backdrop-filter: blur(40px);
  border-radius: 0px 0px 10px 10px;

  @media screen and (max-width: 820px) {
    padding: 24px 20px;
  }

  @media screen and (max-width: 500px) {
    height: 80px;
  }

  @media screen and (max-width: 469px) {
    padding-right: 29.67px;
  }
}

.navbar {
  display: flex;
  min-width: max-content;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 28px;
  border-radius: 10px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  @media screen and (max-width: 820px) {
    padding: 12px 0;
    &:hover {
      background: transparent;
    }
  }
}

.link {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: $whiteColor;

  &__container {
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $headerLinkBorderColor;
    padding-right: 16px;
  }

  &__registred {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: none;
    padding: 12px 0;
    z-index: 0;
    &::after {
      content: '';
      height: 45px;
      width: 100px;
      position: absolute;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      z-index: -1;
      visibility: hidden;
    }

    &:hover::after {
      visibility: visible;
    }

    @media screen and (max-width: 820px) {
      &::after {
        display: none;
      }

      &:hover::after {
        visibility: none;
      }
      
    }

  }

  &:hover {
    cursor: pointer;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;
}

.burger {
  margin: 0;
  border: 0;
  border-radius: 0;
  background: 0;

  & > svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}
