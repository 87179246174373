@import '../../../../../../scss/vars.scss';

.assistant {
  color: $whiteColor;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
  display: flex;
  padding: 16px;
  border-top: 0.5px solid $brownishGreyColor;
  transition: all 0.3s ease-in-out;
  margin-right: 5px;

  &:first-of-type {
    border-top: 0.5px solid transparent;
  }

  &:hover {
    background-color: $backgroundColor;
  }
}

.nonClickable {
  pointer-events: none;
}

.assistant__icon {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.assistant__inner {
  width: 100%;
}

.assistant__title_wrapper {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assistant__titleWrapper {
  display: flex;
  flex-direction: column;
}

.assistant__title {
  flex: 2;
  max-width: 460px;

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
}

.assistant__title_btns {
  display: flex;
  gap: 4px;
}

.pin__btn {
  transform: rotate(-45deg);
  position: relative;

  .text__tooltip {
    top: -16px;
    transform: rotate(45deg);
  }

  &:hover .text__tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.share__btn {
  position: relative;

  .text__tooltip {
    left: -130%;
  }

  &:hover .text__tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.assistant__tags {
  margin-top: 12px;
  width: max-content;
  padding: 2px 8px;
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $suItemsInSubList;
  border: 0.5px solid $brownishGreyColor;
  border-radius: 8px;
}

.text__tooltip {
  visibility: hidden;
  opacity: 0;
  font-size: 12px;
  border-radius: 2px;
  color: $whiteColor;
  z-index: 10000;
  position: absolute;
  border-radius: 2px;
  top: -21px;
  left: -50%;
  padding: 4px 8px;
  background-color: $toastBackgroundDark;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.assistant__description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
  max-width: 460px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
