@import '../../../scss/vars.scss';

.container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.wrapper {
  position: absolute;
  top: 50%;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  padding: 0 5px;

  transform: translate(0, -50%);

  color: #40444b;
  border-radius: 6px;
  background: #fcd570;

  font-family: 'Manrope', sans-serif;
  font-size: 9px;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
}

.isBtn {
  top: 0;
  right: 0;

  transform: translate(30%, -50%);
}

.isText {
  top: 0;
  right: 0;
  font-size: 12px;

  transform: translate(70%, -50%);
}
