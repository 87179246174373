@import '../../scss/typography.scss';
@import '../../scss/vars.scss';

.btnWrapper {
  position: relative;
  z-index: 0;
}

.button {
  position: relative;

  display: flex;
  overflow: hidden;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;

  box-sizing: border-box;
  height: 40px;
  padding: 8px 16px;

  cursor: pointer;
  user-select: none;

  border: none;
  border-radius: 8px;
  outline: none;

  font-family: 'Manrope', Arial, sans-serif;
  font-size: 14px;
  font-weight: $fontWeightMedium;
  line-height: 20px;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .button-spinner {
    width: 20px;
    height: 20px;
  }

  .button-spinner-wrapper {
    border-radius: 4px;
  }

  &-default {
    padding: 5px;
    margin: 0;
    background: none;
    border: none;

    svg {
      width: 24px;
      height: 24px;
      margin-right: unset;
    }
  }

  &-primary-red {
    color: $whiteColor;
    border: 2px solid $primaryColor;
    background: $primaryColor;
    &:hover {
      border-color: $buttonHoverColor;
      background: $buttonHoverColor;
    }
  }

  &-primary-blue {
    color: $whiteColor;
    border: 2px solid $normalBlackberryColor;
    background: $normalBlackberryColor;
    &:hover {
      border-color: $darkBlackberryColor;
      background: $darkBlackberryColor;
    }
  }
  &-primary-gray {
    color: $whiteColor;
    border: 2px solid $greyTertiary;
    background: $greyTertiary;
    &:hover:enabled {
      color: $yellowBackground;
      border-color: $greyTertiary;
      background: $greyTertiary;
      svg {
        path {
          stroke: $yellowBackground;
        }
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  &-secondary-red {
    color: $primaryColor;
    border: 2px solid $linkWaterColor;
    background: $whiteColor;
    &:hover {
      color: $whiteColor;
      border-color: $buttonHoverColor;
      background: $buttonHoverColor;
    }

    .button-spinner {
      border-color: $primaryColor;
    }
  }

  &-secondary-blue {
    color: $pitchBlackColor;
    border: 2px solid $linkWaterColor;
    background: $whiteColor;
    &:hover {
      color: $whiteColor;
      border-color: $deepBlueColor;
      background: $deepBlueColor;
    }

    .button-spinner {
      border-color: $deepBlueColor;
    }
  }

  &-naked {
    color: $deepBlueColor;
    border: 2px solid $whiteColor;
    background: $whiteColor;
    &:hover {
      color: $pitchBlackColor;
      border-color: $whiteColor;
      background: $whiteColor;
    }

    .button-spinner {
      border-color: red;
    }
  }

  &-invisible {
    color: $pitchBlackColor;
    border: 2px solid $whiteColor;
    background: $whiteColor;
    &:hover {
      color: $primaryColor;
      border-color: $whiteColor;
      background: $whiteColor;
    }

    .button-spinner {
      border-color: red;
    }
  }

  &-disabled {
    cursor: default;

    color: $whiteColor;
    border: 2px solid #40444b;
    background: #40444b;
  }

  &-gray {
    color: $whiteColor;
    border-radius: 8px;
    background: #40444b;
    &:hover:enabled {
      color: $whiteColor;
      border-color: $whiteColor;
      background: #2f3136;
    }

    .button-spinner {
      border-color: #2f3136;
    }
  }

  &-yellow {
    width: 197px;
    color: $lightBlackColor;
    border: 2px solid $yellowBackground;
    border-radius: 6px;
    background: $yellowBackground;
    &:hover:enabled {
      color: $lightBlackColor;
      border-color: $normalYellowColor;
      background: $normalYellowColor;
    }

    &:disabled {
      opacity: 0.5;
    }

    .button-spinner {
      border-color: $normalYellowColor;
    }
  }

  &-yellow-signIn {
    width: 100px;
    color: $lightBlackColor;
    border: 2px solid $yellowBackground;
    border-radius: 6px;
    background: $yellowBackground;
    &:hover:enabled {
      color: $lightBlackColor;
      border-color: $normalYellowColor;
      background: $normalYellowColor;
    }

    &:disabled {
      opacity: 0.5;
    }

    .button-spinner {
      border-color: $normalYellowColor;
    }
  }

  &-transparent {
    height: 100%;
    padding: 12px 0;

    color: #95999e;
    border-radius: 0;
    background: transparent;
    // width: 154px;

    &:hover {
      transition: all 500ms ease;

      opacity: 0.4;

      //color: $lightBlackColor;
      //background: transparent;
    }

    .button-spinner {
      border-color: $normalYellowColor;
    }
  }

  &-table {
    width: 32px;
    height: 100%;
    margin-left: 4px;
    padding: 6px;

    color: #95999e;
    border-radius: 4px;
    background: #36393f;

    svg {
      margin-right: 0;
    }

    &:hover {
      color: $lightBlackColor;
      background: #4a4b4e;
    }

    .button-spinner {
      border-color: #4a4b4e;
    }
  }

  &-logo-button {
    width: 107px;
    height: 48px;
    padding: 6px 26px;

    color: $lightBlackColor;
    border-color: #40444b;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: #40444b;
    &:hover {
      color: #40444b;
      border: 2px solid #2f3136;
      background: #2f3136;
    }

    .button-spinner {
      border-color: #40444b;
    }
  }
  &-gray-hover-yellow {
    width: max-content !important;
    padding: 11px 16px;

    color: $whiteColor;
    background-color: #363636;

    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;
    &:hover {
      color: #131313;
      background-color: $yellowBackground;
    }
  }
  &FullWidth {
    width: 100%;
    height: auto;
  }

  &-no-event {
    cursor: default;
    pointer-events: none;
  }

  &SpinnerWrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
  }

  &Spinner {
    display: block;

    animation: spin 1s infinite linear;

    border: 3px solid $whiteColor;
    border-top-color: transparent !important;
    border-radius: 100%;
  }

  &-popover {
    padding: 4px 8px;
    justify-content: start;
    height: unset;
    font-weight: 600;
    color: $suItemsInSubList;
    background: transparent;
    border: none;
    border-radius: 0;

    &-mobile {
      color: $whiteColor;
      background-color: transparent;
    }
    svg {
      margin-left: auto;
      margin-right: 0;
    }
    &:hover {
      color: $whiteColor;
      background: #36393f;
      svg path {
        stroke: $whiteColor;
      }
    }
    &:disabled {
      color: $suItemsInSubList;
      background: transparent;
    }
  }

  &.loading {
    &:after {
      position: absolute;
      z-index: 1;
      left: -40px;

      display: block;

      width: 20px;
      height: 200%;
      margin-left: -50px;

      content: '';
      transform: rotate(45deg);
      animation: movingFlare ease-in-out 2s infinite;

      background: #fff;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0.4) 100%
      );
    }

    @keyframes movingFlare {
      0% {
        left: -50px;

        margin-left: 0px;
      }
      50% {
        left: 110%;

        margin-left: 80px;
      }
      100% {
        left: -50px;

        margin-left: 0px;
      }
    }
  }
}

.active {
  color: $whiteColor;
  background: #36393f;
  svg path {
    stroke: $whiteColor;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
