@import './../../../../scss/vars.scss';
@import './../../../../scss/typography.scss';

.tabs {
  padding-bottom: 80px;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 32px;

    .tabs__header {
      margin: 0;
      margin-bottom: 40px;
      font-size: 44px;
      font-weight: $fontWeightBold;
      line-height: 62px;
      background: linear-gradient(90deg, #e4f4ff 0%, #e2dfff 36.46%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .tabs__btnsList {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 4px;
      margin-bottom: 40px;
      border-radius: 10px;
      background-color: $navLinkColor;
      list-style: none;

      @media screen and (max-width: 768px) {
        background-color: none;
      }

      .tabs__btnItem {
        flex: 1 1 20%;

        .tabs__btn {
          position: relative;
          z-index: 1;
          width: 100%;
          padding: 20px 0;
          border: none;
          border-radius: 6px;
          font-family: 'Inter';
          font-size: 17px;
          font-weight: $fontWeightRegular;
          line-height: 24px;
          color: $navLinkColor;
          background-color: transparent;
          cursor: pointer;
          transition: all 0.4s ease;
          overflow: hidden;

          &::before {
            content: '';
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient($blueCrayolaColor, $blueAzureColor);
            box-shadow: 0px 2px 6px 0px $persianIndigo, 0px -1px 10px 0px $black25opacity inset;
            opacity: 0;
            transition: all 0.4s ease;
          }

          &.active::before {
            opacity: 1;
            transition: all 1s ease;
            @media screen and (max-width: 1150px) {
              opacity: 0;
            }
          }

          &.active {
            font-weight: 600;
            color: $whiteColor;
            transition: all 1s ease;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .tabs {
    padding-bottom: 32px;

    .container {
      max-width: 750px;
      padding: 0 11px;

      .tabs__header {
        margin-bottom: 24px;
        font-size: 30px;
        line-height: 42px;
      }

      .tabs__btnsList {
        flex-wrap: wrap;
        gap: 20px;
        width: auto;
        margin-bottom: 8px;
        background-color: transparent;

        .tabs__btnItem {
          flex: none;
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .tabs {
    padding-bottom: 24px;

    .container {
      max-width: 549px;
      padding: 0 20px;

      .tabs__header {
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 31px;
      }

      .tabs__btnsList {
        padding: 0;
        margin-bottom: 18px;
        .tabs__btnItem {
          .tabs__btn {
            padding: 0;
          }
        }
      }
    }
  }
}
