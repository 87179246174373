@import '../../../../../scss/vars.scss';

.container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 17px;
  cursor: pointer;
}

.text {
  padding-left: 9px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: $whiteColor;
}
