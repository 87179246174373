@import '../../../../scss/vars.scss';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  background: $blackLetters;
  padding-top: 48px;
  // overflow: hidden
}

.scroll {
  display: flex;
  overflow-y: auto;
  flex-direction: column-reverse;
  padding-bottom: 56px;
  flex-shrink: 0;
  z-index: 5;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }
}

.section {
  height: calc(100vh - (105px + 49px));
  box-sizing: border-box;
  display: flex;
  position: fixed;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  position: relative;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 32px;
  width: 100vw;
}

.wrapper {
  width: 100%;
  position: fixed;
  z-index: 9999;
}


.progressBar {
  background: #2F3136;
}
