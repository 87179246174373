.downloadHeaderLink {
  width: 878px;
  height: 72px;
  background-color: rgba(19, 12, 61, 0.50);
  backdrop-filter: blur(40px);
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 16px 80px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: var(--text-100, #FFF);
  border-radius: 10px;
}

.downloadHeaderLink__title_type_bold {
  font-size: 18px;
  font-weight: 700;
}

.downloadHeaderLink__copyLinkButton {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-100, #FFF);
  cursor: pointer;
}

.downloadHeaderLink__copyLinkButton:hover {
  color: var(--light-blue, #80BAF5);
}

.downloadHeaderLink__redirectButton {
  height: 32px;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-100, #FFF);
  border-radius: 10px;
  background: var(--bg-button-accent, linear-gradient(90deg, #304FF8 0%, #2E2BCC 100%));
  box-shadow: 0px 6px 16px 0px rgba(8, 8, 13, 0.23);
  cursor: pointer;
}

.downloadHeaderLink__redirectButton:hover {
  opacity: 50%;
}