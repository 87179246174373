@import '../../../../../../scss/vars.scss';

.answer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 8px 12px;
  gap: 2px;
  min-width: 300px;
  width: 100%;
  background: $backgroundColor;
  border-radius: 16px 16px 16px 0px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $whiteColor;

  &>ol {

    &>li {
      display: flex;
      padding: 0 12px;
      &>p {
        display: list-item;
      }
    }
  }

  pre,
  code {
    background: $darkGrayColor;
    border-radius: 8px;
    padding: 0 4px;
  }
}

.request {
  @extend .answer;
  background: $chatRequestBackgroundColor;
  border-radius: 16px 16px 0px 16px;
}
