.container {
  background-color: #080624;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: 1920px auto;
  cursor: url('../../assets/icons/mouse-pointer.svg'), auto;
  min-height: 100%;
}

.landing {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  animation: showBlock 1s linear forwards;
}

.wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-color: #080624;
  background-size: cover;
}

@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
