.voice {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
