@import '/src/scss/vars.scss';
@import '/src/scss/typography.scss';

.modalContainer {
  width: 548px;
  position: absolute;
  padding: 16px;
  bottom: 110px;
  left: 170px;
  visibility: hidden;
  color: $whiteColor;
  font-family: Manrope;
  background-color: $toastBackgroundDark;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
  border-radius: 20px;
  opacity: 0;
  transition: transform .035s ease-in-out, visibility .05s ease-in-out, opacity .05s ease-in-out;
  transform-origin: bottom;
  transform: scaleY(0.5);
}

.modalContainerActive {
  transition: transform .035s ease-in-out, visibility .05s ease-in-out, opacity .05s ease-in-out;
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);

}
.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.itemList {
  list-style-type: none;
  border-bottom: .3px solid $brownishGreyColor;
  padding: 8px 0;
}

.itemList:last-of-type {
  border-bottom: none;
}

.titleContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.text {
  color: $whiteColor;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.span {
  color: $blueInputPendingColor;
  border-radius: 8px;
  border: 1px solid $blueInputPendingColor;
  padding: 4px 8px;
  margin: 0 3px;
}
.textInfo {
  padding-top: 8px;
  color: $suItemsInSubList;
}
