.invisibleCheckbox {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;

  &:checked + .visibleCheckbox {
    border: 1.5px solid #fbc333;
    background-color: #fbc333;
  }
  &:checked + .visibleCheckbox > div {
    transform: scale(90%);
  }
}

.visibleCheckbox {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 16px;
  height: 16px;

  cursor: pointer;
  transition: all 0.3s ease-out;

  border: 1.5px solid #aaabad;
  border-radius: 2px;
  &__switch {
    width: 13px;
    height: 13px;

    transition: all 0.3s ease-out;
    transform: scale(10%);

    background-image: url('./checkbox.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
  &__minus {
    width: 10px;
    height: 0;

    border: 1px solid #aaabad;
    border-radius: 1px;
  }
}
