@import '../../../scss/vars.scss';

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;

}

.projectImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: $greyTertiary;
  overflow: hidden;
  background-color: #7B61FF;
  transition: all .3s ease-in-out;

  &:hover {
    background: #36393f;
    cursor: pointer;
  }
}

.label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  transform: translate(-50%, -50%);
  opacity: 0;
  cursor: pointer;
}

.chatImg {
  width: 105%;
}

.button {
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #95999E;
}
