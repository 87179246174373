@import '../../../../../../scss/vars.scss';

.ai_command_search {
  display: flex;
  flex-direction: row;
  gap: 4px;
  background-color: $backgroundColor;
  padding: 6px 8px 6px 12px;
  align-items: center;
  border-radius: 4px;

  & > input {
    flex: 1;
    background: transparent;
    border: none;
    overflow: hidden;
    color: $whiteColor;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 0;

    &::placeholder {
      color: $suItemsInSubList;
    }

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 768px) {
  .ai_command_search {
    margin: 8px 8px 16px 8px;
    border-radius: 8px;
  }
}
