@import '../../../../../../scss/vars.scss';

.modal {
  width: 100vw;
  height: 100vh;
  padding: 10px 14px 10px 16px;
  gap: 16px;
  position: fixed;
  bottom: 0;
  background: $blackLetters;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  max-width: 667px;
  z-index: 12;
}

.wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.title {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: $textTertiary;
  
}

.info {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: $whiteColor;
}

.button {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;

  &>svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}

.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $backgroundColor;
  border-radius: 8px;
  width: 100%;
  padding: 6px 8px 6px 8px;
  gap: 8px;

  & .input {
    background: transparent;
    border: none;
    height: 36px;
    width: 100%;
    color: $whiteColor;

    &::placeholder {
      font-family: Manrope;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
    }

    &:focus {
      outline: none;
    }
  }
}

.history {
  height: 480px;
  overflow-y: auto;
  width: 100%;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }
}
