.checkboxButton {
  display: none;
}

.checkboxLabel {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;
  color: #FFFFFF;
  cursor: pointer;
}

.checkboxLabel:before {
  content: '';
  width: 16px;
  height: 16px;
  // margin: 3px;
  border: 1.5px solid #AAABAD;
  border-radius: 4px;
}

.checkboxButton:checked+.checkboxLabel:before {
  background: url('./images/doneIcon.svg') no-repeat center;
  border-color: transparent;
}
