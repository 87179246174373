@import '../../../../../../scss/typography.scss';
@import '../../../../../../scss/vars.scss';

.settings__container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 667px;
  z-index: 10001;
  height: 100vh;
  // overflow-y: scroll;
  background: $blackLetters;
}

.settings__container2 {
  position: relative;
  height: 100%;
}

.setting__tabBar {
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  gap: 8px;
  border-bottom: 0.5px solid $brownishGreyColor;
}

.settings__title {
  padding: 2.5px 16px 14.5px;
  color: $whiteColor;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
