@import './../../../../scss/vars.scss';
@import './../../../../scss/typography.scss';

.start {
  // padding: 113px 0 207px 0;
  width: 100%;
  height: 800px;
  background-image: url('../../components/images/landing-background.png'),
    url('../../../landing/components/images/landing-background2.png');
  z-index: 2;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-color: #080624;
  background-size: cover;
  .container {
    max-width: 1136px;
    padding: 0;
    margin: 0 auto;
    padding-top: 207px;

    .start__greet {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-size: 17px;
      font-weight: $fontWeightMedium;
      line-height: 21px;
      color: $navLinkColor;
    }

    .start__header {
      font-size: 60px;
      font-family: 'Inter';
      font-weight: $fontWeightBold;
      line-height: 78px;
      color: $whiteColor;
      margin-top: 0px;

      .line {
        color: $lightGoldColor;
      }

      .start__selectedText {
        position: relative;
        top: -10px;
        padding: 14px;
        font-size: 32px;
        font-weight: $fontWeightBold;
        line-height: 39px;
        color: $deepPurplishBlueColor;
        white-space: nowrap;
        background-color: $lightGoldColor;
      }
    }

    .start__btn {
      padding: 20px 40px;
      border: none;
      border-radius: 10px;
      font-family: 'Inter';
      font-size: 19px;
      font-weight: 600;
      line-height: 27px;
      color: $whiteColor;
      box-shadow: 0px 6px 16px 0px $blackishBlueColor;
      background: linear-gradient($blueCrayolaColor, $blueAzureColor);
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1150px) {
  .start {
    // padding: 115px 0 100px 0;
    height: 604px;
    background-image: url('../../components/images/landing-background.png'),
    url('../../../landing/components/images/landing-background2.png');
    .container {
      max-width: 750px;
      padding: 0 11px;
      padding-top: 194px;

      .start__header {
        font-size: 40px;
        line-height: 52px;

        .start__selectedText {
          top: -4px;
          padding: 8px 14px;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .start__btn {
        width: 331px;
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .start {
    .container {
      .start__btn {
        width: 305px;
        height: 64px;
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .start {
    padding-bottom: 40px;

    .container {
      max-width: 549px;
      padding: 0 20px;
      padding-top: 194px;

      .start__greet {
        font-size: 13px;
        line-height: 16px;
      }

      .start__header {
        font-size: 28px;
        line-height: 36px;

        .start__selectedText {
          font-size: 13px;
          line-height: 16px;
        }
      }

      .start__btn {
        padding: 16px auto;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .start {
    height: 600px;
    .container {
      .start__btn {
        width: 100%;
        height: 53px;
        padding: 16px 68px 16px 68px;
      }
    }
  }
}
