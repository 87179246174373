@import '../../../../../../scss/vars.scss';

.buttonBlock {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-top: 0.5px solid $brownishGreyColor;
  background: $toastBackgroundDark;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

.button {
  border: 0;
  background: 0;
  border-radius: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 8px;
  gap: 4px;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  &>svg,
  img {
    display: block;
  }

  &>span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

}

.addChatButton {
  @extend .button;
  width: 96px;
  background: $blueInputPendingColor;
  margin-right: 8px;
  transition: all .3s ease-in-out;
  &>span {
    color: $toastBackgroundDark;
  }
  &:hover {
    background-color: $blueInputPendingColorHover;
  }
  &:active {
    background-color: $blueInputPendingColorTarget;
  }
}
.btnIcons {
  position: relative;
  .textTooltip {
    visibility: hidden;
    opacity: 0;
    font-size: 12px;
    border-radius: 2px;
    color: $whiteColor;
    z-index: 100;
    position: absolute;
    border-radius: 2px;
    top: -25px;
    left: -65%;
    padding: 4px 8px;
    background-color:$toastBackgroundDark;
    transition: all .3s ease-in-out;
    transform: scale(1);
  }
  &:active {
    transform: scale(1.05);
  }
  &:hover {
    background-color: $toastBackgroundDark;
    z-index: 100;
    z-index: 10000;
    .textTooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}


