@import '../../../../../../scss/vars.scss';

.menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  // position: fixed;
  bottom: 56px;
  justify-content: space-between;
  background: $toastBackgroundDark;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 667px;
  width: 100%;
  min-height: 72px;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.catalog {
  width: 100%;
  max-width: 667px;
}
