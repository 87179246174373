.padding {
  padding: 16px 16px 0 16px;
}
.blank {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  background-color: #40444B;
  border-radius: 8px;
  padding: 60px 0;
}

.blank__img {
  margin: 0 auto;
}

.blank__title {
  margin: 40px auto 5px auto;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
}

.blank__text {
  margin: 0 auto 32px auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #95999E;
}
