.invisibleCheckbox {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);

  &:checked + .visibleCheckbox {
    background: #fbc333;
  }
  &:checked + .visibleCheckbox > div {
    background: #fff;
    left: 18px;
  }
}

.visibleCheckbox {
  display: block;
  position: relative;
  transition: all 0.3s ease-out;
  width: 39px;
  height: 24px;
  background: #aaabad;
  border-radius: 32px;
  padding: 3px;
  cursor: pointer;
  &__switch {
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.6, 0.2, 0.4, 1.5);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #dbdcdd;
    position: absolute;
    left: 3px;
  }
}
