@import '../../../../scss/typography.scss';
@import '../../../../scss/vars.scss';

.menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // position: fixed;
  //bottom: 56px;
  justify-content: space-between;
  background: $toastBackgroundDark;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 667px;
  width: 100%;
  min-height: 200px;
  border-top: 0.5px solid $brownishGreyColor;
  border-bottom: 0.5px solid $brownishGreyColor;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  position: fixed;
  transition: right 0.3s ease-out;

  &__active {
    position: relative;
  }
}

.catalog {
  border-top: 0.5px solid $brownishGreyColor;
  max-width: 667px;
  width: 100%;
  max-width: 667px;
  padding: 8px;
  height: 162px;
}
