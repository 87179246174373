@import '../../../../../../../../scss/typography.scss';
@import '../../../../../../../../scss/vars.scss';

.tab {
  position: relative;
  padding: 10px 12px;
  border-radius: 6px 6px 0px 0px;
  border-top: 0.5px solid transparent;
  border-right: 0.5px solid transparent;
  border-left: 0.5px solid transparent;
  color: $whiteColor;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
}

.tab::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -2px;
  height: 3px;
  width: 100%;
  background: $blackLetters;
  opacity: 0;
}

.tab_is_active {
  border-color: $brownishGreyColor;
}

.tab_is_active::after {
  opacity: 1;
}
