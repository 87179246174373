@import '../../../../../../../../scss/typography.scss';
@import '../../../../../../../../scss/vars.scss';

.voiceInput {
  display: flex;
  cursor: pointer;
 }

.textarea {
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 12px;
  gap: 12px;
  width: 579px;
  max-height: 320px;
  background: $toastBackgroundDark;
  border: 0;
  border-radius: 8px;
  text-align: bottom;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: $whiteColor;
  outline: none;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  white-space: pre-wrap;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }

  &::placeholder {
    display: block;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}
