.container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  padding: 10%;
  background: #2F3136;
}

.element {
  border: 1px dashed #7b61ff;
  border-radius: 5px;
  padding: 16px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }
}
