@import '../../../../../../scss/vars.scss';

.footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  position: fixed;
  bottom: 0px;
  background: $toastBackgroundDark;
  border-top: 0.5px solid $brownishGreyColor;
  padding-bottom: 16px;
  z-index: 5;
}
