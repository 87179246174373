@import '../../../../../../../../../../scss/vars.scss';
@import '../../../../../../../../../../scss/typography.scss';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  position: relative;

}
.question {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.question:hover .text {
  color: $whiteColor;
}
.svg:hover path {
  fill: $whiteColor;
}
.svg:hover rect {
  stroke: $whiteColor;
}
.text{
  transition: all .2s ease-in-out;
  color: $suItemsInSubList;
  padding-left: 6px;
}
.writeIcon {
  cursor: pointer;
  display: flex;
  padding-right: 10px;
}
