@import '../../scss/vars.scss';
@import '../../scss/typography.scss';

.headerContainer {
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  padding: 0 16px 0 64px;
  background: #2f3136;
  border: solid #666666;
  border-width: 0 0 1px 0;
  z-index: 3;
  left: 0;
  transition: left 0.3s ease;

  &.isOpen {
    position: relative;
    left: calc(100vw - 45px);
  }

  .headerSection {
    align-self: center;
    gap: 24px;

    .headerTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff !important;
    }

    .viewingOnlyText {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-right: 20px;
    }

    .arrow {
      padding: 0;
    }
    .pencilEdit {
      margin-right: 24px;
    }

    .edit {
      margin-left: 24px;
    }
  }
}

.headerContainer,
.headerSection,
.headerInfo {
  display: flex;
  max-height: 24px;
  align-items: center;
  background: #36393f;
}

/*.pencilEdit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 25px;
}*/

@media (max-width: 768px) {
  .headerContainer {
    min-height: 48px;
    padding: 10px 10px;
  }

  button {
    padding: 0;
    border: none;
    background: none;
  }

  .headerSection {
    gap: 16px !important;
  }
}
