@import '/src/scss/vars.scss';
@import '/src/scss/typography.scss';

.itemList {
  list-style-type: none;
  border-bottom: .3px solid $brownishGreyColor;
  padding: 8px 0;
}

.itemList:last-of-type {
  border-bottom: none;
}

.titleContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.text {
  color: $whiteColor;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.span {
  color: $blueInputPendingColor;
  border-radius: 8px;
  border: 1px solid $blueInputPendingColor;
  padding: 4px 8px;
  margin: 0 3px;
}
.textInfo {
  padding-top: 8px;
  color: $suItemsInSubList;
}
