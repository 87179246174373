@import '../../scss/typography.scss';
@import '../../scss/vars.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0 16px 16px 16px;
  top: 50vh;
  right: 50vw;
  transform: translate(50%, -50%);
  background-color: $blackLetters;
  width: 668px;
  height: 517px;
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
  width: 636px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $textTertiary;
  &__icon {
    margin-left: auto;
  }
}
.button_wrapper {
  display: flex;
  justify-content: center;
  width: 636px;
  gap: 30px
}
