.ai_gen_panel {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  background: #2f3136;
  padding: 4px;
  background-color: #2f3136;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #666666;
  color: white;
  transition: opacity 0.5s;
  will-change: transform;
  z-index: 20000;
  left: 0px;
  top: 0px;
}

.ai_gen_panel_btn {
  border: 0;
  display: flex;
  align-items: center;
  background: none;
  border-radius: 3px;
  padding: 8px;
  cursor: pointer;
  //vertical-align: middle;
  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: #36393f;
  }

  & > sup {
    color: white;
    // top: 0.5em;
    font-size: 50%;
    padding-right: 0.5em;
  }
}

.active {
  background-color: #36393f;
}

.ai_menu_panel {
  display: flex;
  position: absolute;
  left: -9999999;
  top: -9999999;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  background: #2f3136;
  padding: 4px;
  background-color: #2f3136;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #666666;
  color: white;
  transition: opacity 0.5s;
  will-change: transform;
  z-index: 20000;
}

.ai_menu_item {
  border: 0;
  display: flex;
  align-items: center;
  background: none;
  border-radius: 3px;
  padding: 6px 8px;
  cursor: pointer;
  gap: 8px;
  width: 100%;
  //vertical-align: middle;
  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: #36393f;
  }
}

.ai_menu_item_title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: white;

  &_blocked {
    color: #606368;
  }
}

.FloatingRegenPanel {
  display: flex;
  position: absolute;
  left: -9999999;
  top: -9999999;
  position: absolute;
  flex-direction: row;
  align-items: center;
  background: #2f3136;
  //   padding: 4px;
  background-color: #2f3136;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #666666;
  color: white;
  transition: opacity 0.5s;
  will-change: transform;
  z-index: 10000;
}

.AITextVariantPopup {
  display: flex;
  position: absolute;
  left: -9999999;
  top: -9999999;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  background: #2f3136;
  padding: 4px;
  background-color: #2f3136;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #666666;
  color: white;
  transition: opacity 0.5s;
  will-change: transform;
  min-width: 240px;
  max-width: 500px;
  z-index: 20000;
}

.AITextVariantPopup_variants {
  border: 0;
  display: flex;
  align-items: center;
  background: none;
  border-radius: 3px;
  padding: 8px;
  cursor: pointer;
  gap: 0.5rem;
  width: 100%;
  //vertical-align: middle;
  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: #36393f;
  }
}

.AITextVariantPopup_variants_title {
  flex: 1;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: white;
  text-align: left;
}

@media (max-width: 768px) {
  .ai_menu_item {
    border-top: 0.5px solid #66666680;
    padding: 10px 12px;
  }

  .ai_menu_itemForPopup {
    width: 100vw;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    &:hover {
      background-color: #36393f;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  .ai_menu_item_titleForPopup {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
    color: white;
  }
  &_blocked {
    color: #606368;
  }
}
