@import '../../../../scss/vars.scss';
@import '../../../../scss/typography.scss';

.tabSection {
  .tabs {
    margin: 11px 0 0 24px;
    padding: 0;
    border-radius: 4px;

    .tab {
      display: flex;
      flex-direction: row;
      margin: 0 0 -1px;
      padding: 10px 12px;
      text-align: center;
      color: gray;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.3px;
      border-radius: 6px 6px 0 0;
      cursor: pointer;

      .icon {
        margin: 0 8px 0 0;
      }
    }

    .tab.active {
      border: solid #666666;
      border-width: 1px 1px 0px 1px;
      background-color: #36393f;
      color: white;

      .iconActive {
        filter: invert(1) brightness(100);
      }
    }
  }
}

.diskSection,
.tabSection,
.group {
  display: flex;
}
