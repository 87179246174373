@import '../../../../scss/vars.scss';
@import '../../../../scss/typography.scss';

button {
  background: none;
  border: none;

  padding: 0;
}

.pencilEdit {
  margin-right: 24px;
}

.imageIcon {
  display: flex;
  width: 24px;
  height: 24px;
}

.edit {
  display: flex;
  margin-left: 24px;
  align-content: center;
}

.burger {
  display: none;
  @media (max-width: 768px) {
    display: flex;

    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid $brownishGreyColor;
  }
}
