@import './normalize.css';
@import './assets/fonts/index.css';
@import 'https://fonts.googleapis.com/css2?family=Inter&family=Roboto&display=swap';

#root {
  white-space: pre-wrap;
  overflow: auto;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Manrope', sans-serif;
  overflow: hidden;
}

#root {
  height: 100%;
  background: #2f3136;
}

div {
  box-sizing: border-box;
}

.MuiPopover-paper {
  background-color: transparent !important;
}

.MuiPaper-root {
  background-color: transparent !important;
}
.MuiPaper-root {
  background-color: transparent !important;
  border-radius: 8px !important;
}

-ul .MuiButtonBase-root {
  min-height: 36px !important;
}

.datepicker.ios {
  padding: 8px 0;
  border-top: 0.5px solid #666666;
  border-radius: 24px 24px 0px 0px;
  background-color: #2f3136 !important;
}

.datepicker-navbar-btn {
  padding: 0 19px !important;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  color: white !important;
}

.datepicker.ios::before {
  content: '';
  position: absolute;
  left: calc(50% - 32px);
  width: 64px;
  height: 4px;
  background-color: #36393f;
  border-radius: 45px;
}

.datepicker.ios .datepicker-content {
  padding: 25px 0 0 !important;
}

.datepicker.ios .datepicker-scroll li {
  color: #95999e !important;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
}

.datepicker.ios .datepicker-scroll li:nth-child(6) {
  color: white !important;
}

.datepicker.ios .datepicker-viewport::after {
  background: none !important;
}

.datepicker.ios .datepicker-navbar {
  border: none !important;
}

.datepicker.ios .datepicker-col-1:first-child div div {
  padding-left: 77px;
}

.datepicker.ios .datepicker-col-1:last-child div div {
  padding-right: 77px;
}

.doc-icon-hidden {
  display: none;
}

.doc-icon-visible {
  display: block;
  margin-right: 15px;
}

@media (max-width: 630px) {
  .MuiPaper-root {
    border-radius: unset !important;
  }
}
