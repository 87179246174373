.link {
  display: flex;
  gap: 6px;
  padding: 8px;
  color: #e0e0e0;
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;

  &:hover {
    border-radius: 8px;
    background: #33363b;
  }
}

@media (max-width: 768px) {
  .link svg {
    transform: scale(1.8);
  }
}


