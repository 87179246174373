// затемненный фон модалки
.overlay {
  width: 668px;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 33000;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #2F3136;  
  position: relative;
  max-height: 235px;
  box-sizing: border-box;
  margin-left: 108px;
  padding: 24px 24px;
}

.shareModal__title {
  color: var(--text-primary, #FFF);
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding-bottom: 16px;
}

.shareModal__text {
  color: var(--text-primary, #FFF);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 16px;
}

.shareModal__linkContainer {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--bg-colors-bg-secondary, #36393F);
  margin-bottom: 16px;
}

.shareModal__link {
  color: var(--text-white, #FFF);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  max-width: 320px;
  background: transparent;
  border: none;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shareModal__copiedInfo {
  color: var(--text-white, #FFF);
  align-self: flex-start;
  opacity: 50%;
}

.shareModal__inputLabel:hover {
  opacity: 50%;
}

.shareModal__button {
  width: 100%;
  height: 40px;
  padding: 10px 16px;
  text-align: center;
  border-radius: 8px;
  background: var(--blue-50, #63B3FF);
  color: var(--primary, #222);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.shareModal__button:hover {
  opacity: 50%;
}

.shareModal__closeButton {
  background-color: transparent;
  border: none;
  align-self: flex-end;
}

.shareModal__closeButton:hover {
  opacity: 50%;
}
