@import '../../../../../scss/vars.scss';

.menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 12px;
  position: absolute;
  z-index: 5;
  height: 32px;
  right: 20px;
  bottom: -32px;
  background: $backgroundColorPrimary;
  border: 0.5px solid  $brownishGreyColor;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;

}

.button {
  border: 0;
  padding: 0;
  background: none;

  &>svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}
