.overlay {
  position: fixed;
  background: #000000;
  opacity: .6;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.modal {
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 345px;
  height: 222px;
  padding: 32px 40px 32px 40px;
  border-radius: 8px;
  
  gap: 12px;
  background: #2F3136;
}

.title {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #FFFFFF;
}

.subtitle {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #95999E;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  gap: 8px;
}

.button {
  border-radius: none;
  border: none;
  padding: 0;
  background: none;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  &>svg {
    display: block;
  }

  transition: 0.45 cursor ease-out;

  &:hover {
    cursor: pointer;
    transition: 0.25 cursor ease-in;
  }
}

.cancelButton {
  @extend .button;
  color: #FFFFFF;
  width: 101px;
  height: 36px;
  padding: 6px 24px 6px 24px;
  border-radius: 8px;
  gap: 8px;
}

.deleteButton {
  color: #2F3136;
  width: 89px;
  height: 40px;
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  gap: 8px;
  background: #ED7274;
}

.spinner {
  margin: auto;
}
