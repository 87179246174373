.label {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #DBDCDD;
  margin: 0;
}

.input {
  width: 100%;
  padding: 8px 12px;
  line-height: 24px;
  font-size: 14px;
  background: #36393F;
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  resize: none;

  &:focus {
    padding: 7px 11px;
    outline: none;
    border: 1px solid #666;
  }
}
