@import '../../scss/vars.scss';

.ring {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.ring:after {
  content: ' ';
  display: block;
  width: 48px;
  height: 48px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  border-color: $pitchBlackColor transparent #fff transparent;
  animation: ring 1.2s linear infinite;
}

.ring.small {
  width: 40px;
  height: 40px;
}

.small.ring:after {
  width: 24px;
  height: 24px;
}

.ring.verySmall {
  width: 16px;
  height: 16px;
}

.verySmall.ring:after {
  width: 8px;
  height: 8px;
  margin: 0;
}

.ring.middleSmall {
  width: 20px;
  height: 20px;
}

.middleSmall.ring:after {
  width: 16px;
  height: 16px;
  margin: 0;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner__text {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
