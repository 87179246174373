@import '../../../../scss/vars.scss';

.tubs {
  display: flex;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  padding: 6px;
}

.tabs_wrapper {
  border-top: 0.5px solid $brownishGreyColor;
  padding: 20px 20px 0px 0px;
}

.tub {
  margin-right: 20px;
  color: $whiteColor;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
  border-bottom: 1px solid transparent;
  padding-bottom: 4px;

  &_active {
    width: fit-content;
    color: $blueInputPendingColor;
    border-bottom: 1px solid $blueInputPendingColor;
  }
}

.title {
  color: $whiteColor;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal {
  position: absolute;
  width: 666px;
  height: 70%;
  border-radius: 20px 20px 0px 0px;
  background: $blackLetters;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  bottom: -70%;
  right: 0px;
  padding: 20px;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  &_active {
    bottom: 0px;
    transition: all 0.3s ease-in-out;
  }
}
.overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 114px;
  width: calc(100% - 114px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all .3s ease-in-out;
  z-index: 1;
  &_active {
    opacity: 1;
    visibility: visible;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  .wrapper {
    flex: 1;
    text-align: center;
  }
  .title {
    text-align: center;
  }
}

.assistant_wrapper {
  margin-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $brownishGreyColor;
  }
}

button.bot_filter_btn {
  margin-right: 14px;
  height: 32px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid $blueInputPendingColor;
  color: $blueInputPendingColor;
  white-space: nowrap;

  &_active {
    color: $toastBackgroundDark;
    background: $blueInputPendingColor;
  }
}

.bot_filter_btn_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: $blueInputPendingColor;
}

.create_assistant_btn {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-top:auto;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  color: $backgroundColorPrimary;
  background: $blueInputPendingColor;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: $blueInputPendingColorHover;
  }
  &:active {
    background: $blueInputPendingColorTarget;
  }
}

.botListModal {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  background-color: $whiteColor;
  transition: bottom 0.3s ease-in-out;

  &_active {
    bottom: 0;
  }
}
