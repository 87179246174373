// BASE COLORS
$backgroundColor: #36393f;
$backgroundColorPrimary: #2f3136;
$backgroundColorVoiceActive: #323D49;
$primaryColor: #e30611;
$errorColor: #f95721;
$buttonHoverColor: #c90000;
$buttonActiveColor: #f51d16;
$footerBackgroundColor: #aeb0b3;
$dangerAlertColor: rgba(255, 228, 233, 0.5);
$successAlertColor: rgba(232, 250, 235, 0.5);
$notificationColor: #ed7274;
$secondaryGigas: #8e3c72;
$headerBackgroundColor: rgba(19, 12, 61, 0.2);
$chatRequestBackgroundColor: #185062;
$selectedImageIconColor: #304E61;

// COLORS
$whiteColor: #ffffff;
$whiteThreeColor: #f7f7f7;
$whiteSmokeColor: #f2f3f7;
$lightGrayishBlue: #e9ebf7;
$linkWaterColor: #e2e5eb;
$lightestRaspberryColor: #ffe4e9;
$lightBlueGreyColor: #d0d6da;
$greyHeatherColor: #bbc1c7;
$brownGreyColor: #b3b3b3;
$greyTertiary: #40444b;
$greyChateauColor: #9198a0;
$greyHover: #aaabad;
$greyRavenColor: #8e9297;
$brownishGreyColor: #666666;
$brownishGreyColorBorder: #6666664d;
$suItemsInSubList: #95999e;
$darkGrayColor: #626c77;
$lightGrayColor: #969fa8;
$lightBlackberryColor: #6384e0;
$normalBlackberryColor: #014fce;
$darkBlackberryColor: #003db7;
$lightestBlackberryColor: #e7eafa;
$normalBlueberryColor: #0097fd;
$darkBlueberryColor: #0077db;
$midnightBlueColor: #202d3d;
$deepBlueColor: #001424;
$pitchBlackColor: #000000;
$blackLetters: #2f3136;
$lightBlackColor: #1d2023;
$lightestYellowColor: #fffde8;
$normalMintColor: #00c19b;
$lightMintColor: #00d9bc;
$lightenMintColor: #c5ede7;
$darkMintColor: #03a17b;
$darkAppleColor: #04aa42;
$lightPlumColor: #a86ea7;
$darkPlumColor: #6d2d79;
$darkRaspberryColor: #c51345;
$megaBrightBlue: #d4dbf0;
$ultraBrightBlue: #45b6fc;
$blueButtonNormal: #007cff;
$normalYellowColor: #fcd570;
$warningColor: #fac031;
$yellowBackground: #fbc333;
$lightOrangeColor: #fa8a64;
$normalOrangeColor: #f95721;
$darkOrangeColor: #e04a17;
$blue-33-color: #33769d;
$textTertiary: #dbdcdd;
$successColor: #4fdc7c;
$toastBackgroundDark: #2b2d32;
$strawberryRed: #d2303e;
$black70percentOpacity: #070002;
$mediumDarkShadeGreyColor: #4d4d4d;
$navLinkColor: rgba(177, 178, 221, 0.5);
$headerButtonBackgroundColor: rgba(255, 255, 255, 0.05);
$headerLinkBorderColor: rgba(255, 255, 255, 0.2);
$lightGoldColor: #ffd9b7;
$borderHeaderAssistent: #616161;
$deepPurplishBlueColor: #0f0a27;
$blackishBlueColor: #08080d3b;
$blueCrayolaColor: #304ff8;
$blueAzureColor: #2e2bcc;
$mediumDarkShadeGreyColor: #4d4d4d;
$blueInputPendingColor: #4bbdea;
$blueInputPendingColorHover: #09A6E3;
$blueInputPendingColorTarget: #02B5FC;
$blueInputPendingColorActive: #00425780;
$blueChatMessageRequestColor: #23819b;
$deepPurpleBlack: #030315;
$persianIndigo: #2b1b8c80;
$black25opacity: #00000040;
$inputBackground: #34363a;
$alfAIMenuItemTextColor: #606368;
$grayPlatinum: #34373d;
$stateGray: #3A3F46;
$lightGrayD9: #d9d9d9;
$black20opacity: rgba(0, 0, 0, 0.2);
$black50opacity: rgba(0, 0, 0, 0.5);
$cornflowerBlue: #4BBDEA80;
$assistantAccentColorOne: #4BBDEA;
$assistantAccentColorTwo: #63B3FF;
$assistantAccentColorThree: #3D4D5C;

// SOCIAL MEDIA COLORS
$vkColor: #4680c2;
$fbColor: #1877f2;
$twColor: #1da1f2;
$okColor: #f7931e;

//SIZES
$formElementsHeight: 44px;
$headerMobileHeight: 56px;
$headerDesktopHeight: 72px;
$viewportMobileMinWidth: 280px;
$customFooterHeight: 96px;
$contentWidth: 592px;

// ANIMATION
$transitionPrimary: 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
$transitionButton: color $transitionPrimary, background $transitionPrimary, border $transitionPrimary;

// SHADOWS
$primaryBoxShadow: 0 2px 8px 0 $lightestBlackberryColor;
$insetBoxShadow: inset 0 2px 8px -5px $lightestBlackberryColor;
$flightPreviewBoxShadow: 0 0 27px -5px $greyHeatherColor;
$headerBoxShadow: 0px 4px 16px rgba(0, 0, 0, 0.16);

// Z-INDEXES
$modalZIndex: 2147483000;
$slideModalZIndex: 2147482000;
$pushZIndex: 2147481000;
