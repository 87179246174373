.chatHistoryPage__dialogItem {
  display: flex;
  width: 100%;
  padding: 32px 0px;
  justify-content: center;
  align-items: center;
  background-color: #171B2A;
}

.chatHistoryPage__dialogItemCopyBtn {
  opacity: 100%;
  width: 28px;
  height: 28px;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 4px;
  background: var(--bg-secondary, #40444B);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.30);
  cursor: pointer
}

.chatHistoryPage__dialogItemCopyBtn_type_unvisible {
  @extend .chatHistoryPage__dialogItemCopyBtn;
    opacity: 0;
}


.chatHistoryPage__dialogItemUser {
  @extend .chatHistoryPage__dialogItem;
  background-color: #01031F;
}

.chatHistoryPage__dialogItemContent {
  width: 742px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.chatHistoryPage__dialogItemText {
  color: var(--text-primary, #FFF);
  max-width: 650px;
  color: var(--text-primary, #FFF);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; 
}