@import '../../../../../../../../scss/vars.scss';

.inputSection {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px 0 17px;
  &>svg {
    display: block;
  }
}

.textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 279px;
  width: 100%;
  min-height: 40px;
  max-height: 320px;
  height: 40px;
  padding: 16px 12px 0 12px;
  background: $toastBackgroundDark;
  border-radius: 8px;
  outline: none;
  resize: none;
  border: 0;
  color: $whiteColor;

  &::placeholder {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $suItemsInSubList;
  }
}

.button {
  border: 0;
  background: 0;
  padding: 0;

  &>svg {
    display: block;
  }
}
