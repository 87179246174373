.extensionLinkBtn {
  width: 224px;
  height: 56px;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: rgba(128, 186, 245, 0.20);
  color: var(--text-primary, #FFF);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  }

.extensionLinkBtn__сontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.extensionLinkBtn__title {
  color: var(--text-primary, #FFF);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 24px;
}
