.burger__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 70px);
    right: 0;
    top: 70px;
    background-color: #000000;
    z-index: 1;
    padding: 0 30px;
    justify-content: space-around;
}

.bm__close {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../../../assets/icons/plus.svg');
    transform: rotate(90deg);
    border: none;
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0);
}
.bm__select {
    display: none;
}
.bm__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header__link {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 20px;
 }
 .header__btn {
    text-align: center;
    background: none;
    border-radius: 8px;
    border: 1px solid white;
    box-sizing: border-box;
    color: #FFFFFF;
    padding: 11px 16px;
    margin-bottom: 20px;
  }
  .header__btn_reg {
    text-align: center;
    background: #FBC333;
    border-radius: 8px;
    padding: 11px 16px;
    border: none;
    outline: none;
    color: #131313;
  }
  .header__btn_container {
      display: flex;
      flex-direction: column;
  }

