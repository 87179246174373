@import '../../scss/typography.scss';
@import '../../scss/vars.scss';

.container {
  box-sizing: border-box;
  display: flex;
  min-width: 444px;
  width: max-content;
}

.modal {
  display: flex;
  height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: start;
  background: $backgroundColorPrimary;
  border: 1px solid $brownishGreyColor;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.chat {
  display: flex;
  flex-direction: column;
  padding: 16px;

}

.closeButton {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $whiteColor;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin: 28px 0 0 0;
  gap: 8px;
  width: 114px;
  height: 48px;
  border: 0;
  background: $blackLetters;
  border-radius: 8px 0px 0px 8px;

  &>svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}
