@import '../../../../../src/scss/vars.scss';

.ai_command_catalog {
  display: flex;
  flex-direction: column;
  // gap: 8px;
  width: 100%;

  .ai_command_button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 6px;
    padding: 8px;
    background-color: transparent;

    .ai_command_button_text {
      padding-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $whiteColor;
    }

    &:hover {
      background-color: $grayPlatinum;
    }

    &:active {
      background-color: transparent;
    }
  }

  .ai_command_hr {
    margin: 6px -8px 2px -8px;
    border: 0.5px solid #666666;
    transform: scaleY(0.5);
  }
}

.ai_command_title {
  text-transform: uppercase;
  color: #95999e;
  padding-left: 4px;
  font-size: 10px;
  font-weight: 700;
  margin: 0;
  line-height: 32px;
}

.ai_command_container {
  // height: 150px;
  padding-top: 8px;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 180px; //for dinamic load
}

.ai_command_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #606368;
  padding: 6px 8px;
}

.ai_command_load {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .ai_command_container {
    padding: 0 0 18px 0;
    margin-top: 0;
    border-top: 0.5px solid #66666680;
    max-height: 100vh; //for dinamic load
  }

  .ai_command_title {
    border-top: 0.5px solid #66666680;
    padding-top: 8px;
    padding-left: 16px;
  }
}
