@import '../../../../../scss/typography.scss';
@import '../../../../../scss/vars.scss';


.icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 100%;
}

.btn_add_assistant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #7B61FF;
  border-radius: 50%;
}

.const_btn_add_assistant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;

}

.close {
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
}

.assistant {
  position: relative;
  padding: 12px 6px;

  .tooltip {
    position: absolute;
    top: 114%;
    left: 50%;
    z-index: 2;
    display: none;
    padding: 4px 8px;
    transform: translateX(-50%);
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 2px;
    background: $toastBackgroundDark;

    & &_text::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent $toastBackgroundDark transparent;
    }
  }


  &:hover {
    background: #3D4D5C;
    transition: background 0.3s ease;
    z-index: 10000;

    .tooltip {
      display: block;
    }

    .close {
      opacity: 1;
      visibility: visible;
    }
  }

  &_active {
    box-shadow: 0px -2px 0px 0px $blueInputPendingColor inset;
    background: #3D4D5C;

    & .close {
      opacity: 1;
      visibility: visible;
    }
  }
}

.assistantListRemnantBtn {
  width: 32px;
  height: 32px;
  color: $textTertiary;
  border: 1px solid $textTertiary;
  border-radius: 50%;
  line-height: 1.9;
}

.nonСlickable {
  pointer-events: none;
}

.AssistantIconList {
  display: flex;
}

.assistantsRemnantList {
  list-style: none;
  position: absolute;
  z-index: 100;
  display: flex;
  width: 264px;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  right: 16px;
  top: 73px;
  border-radius: 8px;
  border: 0.5px solid #666;
  background: #36393F;

  .assistant {
    text-align: left;
    display: flex;
    align-items: center;

    &_active {
      box-shadow: 2px 0px 0px 0px $blueInputPendingColor inset;
    }
  }

  .span {
    display: flex;
    align-items: center;
  }

  button {
    display: flex;
    width: 100%;

    .avatarWrapper {
      margin-left: 8px;
      margin-right: 10px;
    }

    span {
      height: 40px;
      color: $whiteColor;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 143%;
      letter-spacing: 0.3px;
    }
  }

  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }
}
