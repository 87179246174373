@import '../../../../src/scss/vars.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $backgroundColorPrimary;
  color: $textTertiary;
  & label {
    padding: 24px 0px 8px 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  & textarea {
    color: $textTertiary;
    background-color: $backgroundColor;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    resize: none;
    border: none;
    padding: 8px 12px;
    border-radius: 8px;
    height: 40px;
    max-height: 200px;
    overflow: hidden;
    &:focus {
      outline: none;
      border: 1px solid $yellowBackground;
    }
  }
  .stroke {
    color: #606368;

    &::placeholder {
      color: #606368;
    }
  }

  .height {
    height: 200px;
  }

  .resize {
    resize: both;
    margin-bottom: 20px;
  }

  .error {
    border: 1px solid $errorColor;
    &:focus {
      border: 1px solid $errorColor;
    }
  }
}
