@import '../../../../scss/vars.scss';
@import '../../../../scss/typography.scss';

.footer {
  overflow: hidden;
  position: sticky;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  padding: 40px 0px;
  background: $deepPurpleBlack;

  @media screen and (max-width: 1150px) {
    position: static;
    padding: 20px;
  }

  .dots {
    position: absolute;
    z-index: -1;
    opacity: 1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: url(../../../../assets/icons/dot.png);
    background-size: 20px 10px;
    background-repeat: repeat;
  }

  .footer__container {
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 0 32px;
    margin: 0 auto;

    @media screen and (max-width: 1150px) {
      max-width: 750px;
      padding: 0 11px;
    }

    @media screen and (max-width: 630px) {
      max-width: 549px;
      padding: 0;
    }

    .footer__main {
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 48px;

      .footer__header {
        font-size: 44px;
        font-weight: $fontWeightBold;
        line-height: 62px;
        color: $whiteColor;

        @media screen and (max-width: 1150px) {
          font-size: 30px;
          line-height: 42px;
        }

        @media screen and (max-width: 630px) {
          font-size: 22px;
          line-height: 31px;
        }

        @media screen and (max-width: 380px) {
          max-width: 200px;
          text-align: center;
        }
      }

      .footer__navSocial {
        display: flex;
        gap: 51px;

        .footer__link {
          display: block;
          width: 50px; 
          svg {
            width: 50px;
            height: 53.38px;
            transition: width 0.3s, height 0.3s;
            &:hover {
              width: 40px;
              height: 40px;
            }
          }
        }

        @media screen and (max-width: 630px) {
          gap: 25px;

          svg {
            width: 40px;
            height: 40px;
          }
        }
      }

      .footer__warning {
        font-family: 'Inter';
        font-size: 15px;
        font-weight: $fontWeightRegular;
        line-height: 21px;
        text-align: center;
        color: $navLinkColor;
      }
    }

    .footer__bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;
      align-items: center;
      padding: 12px 0;

      @media screen and (max-width: 500px) {
        height: 127px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding-top: 0;
        padding-bottom: 16px;
      }
    }
  }
}

.footer__title {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: $navLinkColor;
}

.footer__navDoc {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer__linkDoc {
  @extend .footer__title;
}
