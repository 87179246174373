@import '../../../../scss//typography.scss';
@import '../../../../scss/vars.scss';

.menuContainer {
  box-sizing: border-box;
  position: absolute;
  right: -100%;
  bottom: 56.5px;
  transition: right 0.5s ease-out;
  max-width: 667px;
}

.menuContainer__active {
  width: 668px;
  right: 0;
  border-left: 0.5px solid $brownishGreyColor;
  z-index: 999;
  background: $toastBackgroundDark;
}

.footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  gap: 10px;
  width: 100%;
  max-width: 667px;
  min-height: 56px;
  background: $toastBackgroundDark;
  border-top: 0.5px solid $brownishGreyColorBorder;
  border-bottom: 0.5px solid $brownishGreyColor;
  z-index: 999;
}

.button {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  &>svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}

.plusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: cursor .5s ease-in-out;

  &>svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}
