@import '../../../../../../scss/vars.scss';

.search_input_wrapper {
  display: flex;
  width: 627px;
  height: 40px;
  align-items: center;
  padding: 8px 16px;
  margin: 12px 0 24px;
  gap: 12px;
  border-radius: 4px;
  background: $backgroundColor;
  border: 1px solid transparent;
  transition: border 0.2s ease-in-out;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  cursor: pointer;
  &:has(.search_input:focus) {
    border: 1px solid $brownishGreyColor;
  }
  &:hover {
    border: 1px solid $brownishGreyColor;
  }
}
.search_input {
  border: 0;
  background: transparent;
  width: 100%;
  color: $whiteColor;
  outline: none;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  &::placeholder {
    align-items: start;
    font-size: 14px;
  }
}
.search__btn {
  display: flex;
}
.reset__btn {
  padding: 5px;
}

.svg {
  display: flex;
  &:hover path {
    stroke: #fff;
  }
  circle {
    fill: transparent;
    transition: all 0.2s ease-in-out;
  }
  path {
    transition: all 0.2s ease-in-out;
  }
}
