@import '../../../../../../../../scss/vars.scss';

.item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 96px;
  padding: 8px 0px 8px 0px;
  border-width: 1px 0px 0px 0px;
  gap: 4px;
  border-color: $brownishGreyColor;
  border-style: solid;

  &:last-child {
    border-bottom: 1px solid $brownishGreyColor;
  }
}

.title {
  display: block;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: $whiteColor;
}

.answer {
  display: block;
  max-width: 627px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: $textTertiary;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.45s cursor ease-out;

  &:hover {
    cursor: pointer;
    transition: 0.25s cursor ease-in;
  }
}

.changeDate {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: $suItemsInSubList;
}

.button {
  border-radius: none;
  border: none;
  padding: 0;
  background: none;

  &>svg {
    display: block;
  }

  transition: 0.45s cursor ease-out;

  &:hover {
    cursor: pointer;
    transition: 0.25s cursor ease-in;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
}

.titleWrapper {
  display: flex;
  gap: 12.5px;
  justify-content: space-between;
}

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 94%;
}
