@import '../../../../../../../../scss/vars.scss';

.svg {
  fill: transparent;
  stroke: $suItemsInSubList;
  transition: all .2s ease-in-out;
  &:hover {
    stroke: $blueInputPendingColor;
  }
  &:active {
    fill: $blueInputPendingColorActive;
    stroke: $blueInputPendingColor;
  }
}
