@import '../../../../scss/vars.scss';

.container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  z-index: 9999999;
}

.wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  width: 672px;
  height: 100vh;
  background-color: $backgroundColorPrimary;
  color: $textTertiary;
  position: relative;
}

.splash {
  width: calc(100% - 116px);
  height: 100%;
  background-color: $backgroundColorPrimary;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0.5;
  z-index: 10;
  right: 0px;
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.title__team {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 24px 0 8px 0;
}

.btn {
  display: flex;
  width: 114px;
  justify-content: space-between;
  align-items: center;
  color: $whiteColor;
  background-color: $backgroundColor;
  padding: 12px 16px 12px 16px;
  border: none;
  border-radius: 8px 0px 0px 8px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.btn__yellow {
  width: 220px;
  height: 40px;
  border-radius: 8px;
  margin: 0px;
  color: $backgroundColorPrimary;
  background-color: $yellowBackground;
  padding: 8px 57.5px;
}

.btn__gray {
  width: 144px;
  height: 40px;
  border-radius: 8px;
  margin: 0px 0px 0px 16px;
  padding: 8px 16px;
  color: $notificationColor;
  background-color: $backgroundColor;
}

.input {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  background-color: $backgroundColor;
  padding: 8px 12px;
  border-radius: 8px;
  color: $whiteColor;
  border: none;
  outline: none;
  caret-color: $yellowBackground;
  width: 100%;

  &::placeholder {
    color: $suItemsInSubList;
  }

  &:focus {
    border: 1px solid $yellowBackground;
  }
}

.textarea {
  overflow: auto;
  border: none;
  resize: none;
  width: 100%;
  min-height: 256px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    margin-top: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: $suItemsInSubList;
    border: 1px solid $suItemsInSubList;
    border-radius: 45px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.text {
  padding-bottom: 8px;
}

.desc__team {
  min-height: 540px;
  height: 540px;
  overflow-y: auto;
}

.button__box {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
}

.disabled {
  opacity: 0.5;
}

.active__box {
  border: 1px solid $yellowBackground;
}

.category__input {
  border: none;
  outline: none;
  flex-grow: 0.5;
  background-color: $inputBackground;
  color: $whiteColor;
}

.category__box {
  position: relative;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  background-color: $inputBackground;
}

.category__relative {
  position: relative;
}

.category__info {
  position: absolute;
  width: 612px;
  height: 86px;
  background-color: $toastBackgroundDark;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px 12px;
  gap: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  overflow: auto;
}

.category__info__title {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  padding: 8px 0px 0px 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $suItemsInSubList;
}

.category__info__box {
  display: flex;
}

.category__info__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 6px;
  width: 100%;
  &:hover {
    background-color: $greyTertiary;
    cursor: pointer;
    border-radius: 6px;
    gap: 8px;
  }
}

.span {
  cursor: pointer;
}
