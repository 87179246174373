@import '../../../../scss/typography.scss';
@import '../../../../scss/vars.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 667px;
  height: 56px;
  border-bottom: 0.5px solid $brownishGreyColor;
  box-sizing: border-box;
  padding: 0px 14px 0px 16px;
  background: $backgroundColorPrimary;

  &>svg {
    display: block;
  }

  &__title {
    display: block;
    color: $whiteColor;
    margin: 0;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;

  }
}

.icon {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    border-right: 1px solid $borderHeaderAssistent;
    height: 24px;
    right: 0px;
  }

}

.btn_add_assistant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: $blueInputPendingColor;
  border-radius: 50%;
  position: relative;
  margin-left: 16px;
  transition: all .2s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    border-left: 1px solid $borderHeaderAssistent;
    height: 24px;
    left: -15px;
  }

  &:hover {
    background-color: $blueInputPendingColorHover;
  }

  &:active {
    background-color: $blueInputPendingColorTarget;
  }
}

.const_btn_add_assistant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.assistant {
  padding: 12px 6px;

  &_active {
    box-shadow: 0px -2px 0px 0px $blueInputPendingColor inset;
    background: $assistantAccentColorThree;
  }
}

/* .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 667px;
  border-bottom: 0.5px solid $brownishGreyColor;
  box-sizing: border-box;
  padding: 0px 14px 0px 16px;
  background: $backgroundColorPrimary;

  &>svg {
    display: block;
  }

  &__title {
    display: block;
    color: $whiteColor;
    margin: 0;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;

  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.btn_add_assistant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #4BBDEA;
  border-radius: 50%;
}

.const_btn_add_assistant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;

}

.assistant {
  padding: 12px 6px;

  &_active {
    box-shadow: 0px -2px 0px 0px #4BBDEA inset;
    background: #3D4D5C;
  }
} */
