@import '../../scss/typography.scss';
@import '../../scss/vars.scss';

.button {
  border: 0;
  // margin: 0 16px 0 0;
  padding: 0;
  background: 0;

  & > svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}

/*@media (max-width: 375px) {
  .button {
    display: none;
  }
}*/
