@import '../../../../../../scss/typography.scss';
@import '../../../../../../scss/vars.scss';


.buttons {
  display: flex;
  gap: 16px;
}

.button {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 0;
  border-radius: 8px;
}


.deleteButton {
  @extend .button;
  width: 104px;
  color: $notificationColor;
  background: transparent;
  transition: background .3s ease-in-out;

  &:hover {
    background: #32353A;
  }
}
