@import '../../../../scss/typography.scss';
@import '../../../../scss/vars.scss';

.section {
  display: flex;
  flex-direction: column;
  width: 667px;
  padding: 24px 20px;
  gap: 24px;
  background: $blackLetters;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100000;
  height: 90vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brownishGreyColor;
  }
}

.sectionCreate {
  height: 100vh;
}


.header {
  display: flex;
  flex-direction: column;
  width: 95px;
  gap: 24px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $whiteColor;
  margin: 0;
  cursor: default;
}

.subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $textTertiary;
  margin: 0;
  cursor: default;
}

.label {
  display: flex;
  flex-direction: column;
  width: 192px;
  gap: 8px;
}

.labelModel {
  @extend .label;
  width: 100%;
}

.description {
  padding: 4px 8px;
  font-size: 14px;
  line-height: 24px;
  color: $whiteColor;
  border-radius: 4px;
  border: 1px solid $brownishGreyColor;
  background: #80baf533;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.disabledBlock {
  /* Блокировка кликов */
  pointer-events: none;
  opacity: 0.5;
}

.sourcesBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.checkboxBlock {
  display: flex;
  gap: 8px;
}

.switchBlock {
  display: flex;
  gap: 8px;
  font-size: 14px;
  line-height: 16px;
  color: $whiteColor;
  cursor: default;
}

.checkboxSpanBlock {
  display: flex;
  flex-direction: column;
}

.span {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $textTertiary;
  cursor: default;
}

.spanThin {
  @extend .span;
  font-weight: 400;
  color: $suItemsInSubList;
}

.followSourcesButton {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $textTertiary;
}

.addSourceButton {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: $blueInputPendingColor;
  // border: 1px solid #4BBDEA;
}

.buttons {
  display: flex;
  gap: 16px;
}

.button {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 0;
  border-radius: 8px;
}

.cancelButton {
  @extend .button;
  width: 101px;
  color: #AAABAD;
  background: transparent;
  transition: background .3s ease-in-out;

  &:hover {
    background: #32353A;
  }

  &:active {
    color: $whiteColor;
  }
}

.button {
  border: 0;
  // margin: 0 16px 0 0;
  padding: 0;
  background: 0;

  &>svg {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}

.valueWindow:hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 999;
}
