@import '../../../../../../../../scss/vars.scss';

.buttonBlock {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 0.5px solid $brownishGreyColor;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.button {
  border: 0;
  background: 0;
  border-radius: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 8px;
  gap: 4px;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);

  &>svg,
  img {
    display: block;
  }

  &>span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}

.addChatButton {
  @extend .button;
  width: 96px;
  background: $cornflowerBlue;

  &>span {
    color: $toastBackgroundDark;
  }

}

.historyButton {
  @extend .button;
  width: 86px;
  border: 1px solid $cornflowerBlue;

  &>span {
    color: $cornflowerBlue;
  }
}

.historyButton:hover {
  background-color: #004257
}

.chatChangeButton {
  @extend .button;
  width: 57px;
  border: 1px solid $cornflowerBlue;

  &>span {
    color: $cornflowerBlue;
  }
}
