@import '../../../../../../../scss/vars.scss';
.svg {
  border-radius: 4px;
  transition: all .2s ease-in-out;
  & path{
    transition: all .2s ease-in-out;
  }
  & circle{
    transition: all .2s ease-in-out;
  }
  &:hover {
    background-color: $backgroundColor;
  }
  &:hover path{
    stroke: $blueInputPendingColor;
  }
  &:hover circle{
    stroke: $blueInputPendingColor;
  }
}
.svgFill {
  border-radius: 4px;
  transition: all .2s ease-in-out;
  & path{
    transition: all .2s ease-in-out;
  }
  &:hover {
    background-color: $backgroundColor;
  }
  &:hover path{
    fill: $blueInputPendingColor;
  }
}
