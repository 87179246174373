.button {
  background: #fbc333;
  border-radius: 8px;
  padding: 8px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2f3136;
  border-color: transparent;
  &:hover {
    cursor: pointer;
    background: #FCD570;

  }
  &:active {
    // transform: scale(98%);
  }
  &:disabled {
    background: #36393F;
    color: #FFFFFF;
    cursor: auto;
  }
}
