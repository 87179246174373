.button {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 0;
  border-radius: 8px;
}

.saveButton {
  @extend .button;
  width: 220px;
  color: #2B2D32;
  background: #4BBDEA;
  opacity: 0.5;
  cursor: default;

  &.active {
    opacity: 1;
    cursor: pointer;
    transition: background .3s ease-in-out;

    &:hover {
      background: #09A6E3;
    }
  }
}
