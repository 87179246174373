@import '../../scss/vars.scss';

.customToast {
  position: relative;

  display: flex;
  overflow: hidden;
  justify-content: space-between;

  box-sizing: border-box;
  min-width: 300px;
  min-height: var(--toastify-toast-min-height);
  max-height: var(--toastify-toast-max-height);
  margin-bottom: 1rem;
  padding: 8px;

  cursor: pointer;

  color: #ffffff;
  border-radius: 10px;
  background: #2b2d32;

  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;

  direction: ltr;
}

.customBody {
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  min-width: 300px;
  max-width: 30vw;
  margin: auto 0;
  padding: 10px;
}

.customToast {
  width: 100%;
  border: 0.5px solid $brownishGreyColor;
  background: #2b2d32;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

/* Toastify default styles override */
:root {
  --toastify-toast-width: fit-content !important;
  --toastify-color-progress-dark: #666666 !important;
}
