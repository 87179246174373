@import '../../../../../scss/vars.scss';
@import '../../../../../scss/typography.scss';

.button {
    border: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 28px;
    gap: 12px;
    width: 212px;
    height: 45px;
    background: $headerButtonBackgroundColor;
    border-radius: 10px;
    color: $whiteColor;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;

    &:hover {
        cursor: pointer;
        background: linear-gradient(-45deg, rgba(8, 14, 20, 1) 0%, rgba(255, 255, 255, 0) 50.9%);
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1);
    }
}
